import React, { useEffect, useState } from "react";
import classes from "../faq/FaqHolder.module.css";
import { Box, Container, Text, Title } from "@mantine/core";
import QuittingData from "../../storage/quittingStorage.json";
import { useFaqData } from "../../stores/faq-data";

const Quitting = ({ language }) => {
  const [data, setData] = useState(QuittingData);
  const { searchValue } = useFaqData();

  useEffect(() => {
    if (searchValue) {
      data[language].items?.map((i) => {
        if (
          i.title?.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
          i.content?.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
        ) {
          i.hide = false;
        } else {
          i.hide = true;
        }
      });
      setData(data);
    } else {
      let newObj = { ...data };
      newObj[language].items?.map((i) => {
        return (i.hide = false);
      });
      setData(newObj);
    }
  }, [searchValue]);

  return (
    <Container size="sm" className={classes.wrapper}>
      <Title
        ta="center"
        style={{ color: "#440091" }}
        className={classes.title}
        mb={20}
      >
        {data[language].title}
      </Title>
      <Box p={30} mt={30}>
        {language && data
          ? data[language].items?.map((d, i) => {
              return (
                !d.hide && (
                  <Box key={i}>
                    <Title
                      style={{ color: "#440091" }}
                      m={0}
                      order={4}
                      my={30}
                      ta="left"
                    >
                      {d.title}
                    </Title>
                    <Text
                      dangerouslySetInnerHTML={{ __html: d.content }}
                    ></Text>
                  </Box>
                )
              );
            })
          : null}
      </Box>
    </Container>
  );
};

export default Quitting;
