import React, { useState } from "react";
import { Box, Image, Text, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import i1 from "../../assets/images/1.png";
import i2 from "../../assets/images/2.png";
import i3 from "../../assets/images/3.png";
import R from "../../assets/images/R.png";
import homepage from "../../storage/homepage.json";
import { Parallax } from "react-scroll-parallax";

const How = ({ language }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: 56.25em)`);
  const [data, setData] = useState(homepage);

  const qa = [
    {
      q: "Find your product",
      a: "Simply search for products, symptoms or brands",
    },
    {
      q: "Order with a few clicks",
      a: "Order and pay directly via PayPal, Apple/Google Pay or credit card.",
    },
    {
      q: "Direct Delivery",
      a: "In approximately 30 minutes or at a time of your desire the order will be delivered to your doorstep.",
    },
  ];

  return (
    <Box
      style={{
        padding: isMobile ? "2rem 1rem" : "4rem 6rem",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        marginBottom: isMobile ? "2rem" : "9rem",
      }}
    >
      <Box
        style={{
          width: isMobile ? "100%" : "34%",
        }}
      >
        <Text
          style={{
            marginTop: "2rem",
            color: "#ce0085",
            fontSize: isMobile ? "2rem" : "3rem",
            fontWeight: 700,
          }}
        >
          {data[language].how.title}
        </Text>

        <Box
          style={{
            position: "relative",
            display: isMobile ? "block" : "none",
          }}
        >
          <Image
            src={i1}
            alt="i1"
            style={{
              width: "60%",
              opacity: 0,
            }}
          />
          <Image
            src={i1}
            alt="i1"
            style={{
              position: "absolute",
              width: "60%",
              top: 0,
              left: 0,
              transform: "translate(-10%, 10%)",
            }}
          />
          <Box
            style={{
              position: "absolute",
              width: "60%",
              top: 0,
              left: 0,
              transform: "translate(30%, 0%)",
            }}
          >
            <Parallax translateY={[0, 25]}>
              <Image src={i2} alt="i2" />
            </Parallax>
          </Box>
          <Image
            src={i3}
            alt="i3"
            style={{
              position: "absolute",
              width: "60%",
              top: 0,
              left: 0,
              transform: "translate(80%, 10%)",
            }}
          />
        </Box>
        <Box
          style={{
            marginTop: "2rem",
          }}
        >
          {data[language].how.items.map((q, i) => (
            <Box key={q.q}>
              <Text
                style={{
                  marginTop: "0.5rem",
                  color: "#440086",
                  fontSize: isMobile ? "1rem" : "1.5rem",
                  fontWeight: 700,
                }}
              >
                {i + 1}. {q.q}
              </Text>
              <Text
                style={{
                  marginTop: "0.5rem",
                  fontSize: isMobile ? "1rem" : "1.5rem",
                  fontWeight: 400,
                  color: "#242424",
                }}
              >
                {q.a}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
      {/* <Parallax translateY={[0, 0]}> */}
      <Box
        style={{
          display: isMobile ? "none" : "block",
          position: "relative",
          width: "60%",
        }}
      >
        <Image
          src={i1}
          alt="i1"
          style={{
            position: "absolute",
            width: "50%",
            opacity: 0,
            top: 0,
            left: 0,
            transform: "translate(-15%, 10%)",
          }}
        />
        <Box
          style={{
            position: "absolute",
            width: "50%",
            top: 0,
            left: 0,
            transform: "translate(22%, -7%)",
          }}
        >
          {/* <Parallax translateY={[0, 5]}> */}
          <Image src={i1} alt="i1" />
          {/* </Parallax> */}
        </Box>
        <Box
          style={{
            position: "absolute",
            width: "50%",
            top: 0,
            left: 0,
            transform: "translate(62%, -15%)",
          }}
        >
          <Parallax translateY={[0, 25]}>
            <Image src={i2} alt="i2" />
          </Parallax>
        </Box>

        <Image
          src={i3}
          alt="i3"
          style={{
            position: "absolute",
            width: "50%",
            top: 0,
            left: 0,
            transform: "translate(112%, -7%)",
            zIndex: 1,
          }}
        />
        <Image
          src={R}
          alt="R"
          style={{
            position: "absolute",
            width: "30%",
            top: "10%",
            left: "80%",
            transform: "rotate(-40deg)",
          }}
        />
        <Image
          src={R}
          alt="R"
          style={{
            position: "absolute",
            width: "20%",
            top: "44%",
            left: "61%",
            transform: "rotate(-10deg)",
          }}
        />
      </Box>
      {/* </Parallax> */}
    </Box>
  );
};

export default How;
