import { create } from "zustand";

type FaqDataWithSearch = {
  searchValue: string;
  data: any[] | [];
  setData: (cust: any) => void;
  setSearchValue: (sv:any) => void;
};

export const useFaqData = create<FaqDataWithSearch>((set) => ({
    searchValue: "",
    data: [],
    setData: (someData) => set(() => ({ data: someData })),
    setSearchValue: (sv) => set(() => ({ searchValue: sv }))
}));
