import React, {useState} from "react";
import {
  Box,
  Text,
  Accordion,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import plus from "../../assets/images/plus.svg";
import meds from "../../assets/images/meds.png";
import homepage from "../../storage/homepage.json";

const Faq = ({language}) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: 56.25em)`);
  const [data, setData] = useState(homepage);



  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        padding: isMobile ? "2rem 1rem" : "4rem 6rem 0",
        backgroundColor: "#440086",
        position: "relative",
      }}
    >
      <Text
        style={{
          color: "#ce0085",
          fontSize: isMobile ? "26px" : "36px",
          padding: "1rem 0",
          textAlign: isMobile ? "center" : "left",
          fontWeight: 700,
        }}
      >
        {data[language].faq.title}
      </Text>
      <Box
        style={{
          width: isMobile ? "100%" : "70%",
          margin: "2rem auto",
        }}
      >
        {data[language].faq.items.map((item, index) => (
          <Accordion
            key={index}
            styles={{
              control: {
                backgroundColor: "transparent",
                color: "#fff",
                '&:hover': {
                  backgroundColor: "transparent",
                },
              },
              item: {
                borderBottom: "1px solid #fff",
              },
              panel: {
                backgroundColor: "transparent",
                color: "#fff",
              },
            }}
          >
            <Accordion.Item value={`panel${index}`}>
              <Accordion.Control>
                <Box
                  style={{
                    display: "flex",
                  }}
                >
                  <img
                    src={plus}
                    alt="plus icon"
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "1rem",
                    }}
                  />
                  <Text>{item.q}</Text>
                </Box>
              </Accordion.Control>
              <Accordion.Panel>
                <Text>{item.a}</Text>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        ))}
      </Box>
      <Box
        style={{
          position: "absolute",
          right: "12rem",
          top: "3rem",
          display: isMobile ? "none" : "block",
          width: "12vw",
        }}
      >
        <img src={meds} alt="meds" style={{ width: "100%" }} />
      </Box>
    </Box>
  );
};

export default Faq;
