import { Box, Text } from "@mantine/core";
import React, { useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import ig from "../../assets/images/ig.svg";
import fb from "../../assets/images/fb.svg";
import x from "../../assets/images/x.png";
import { Link } from "react-router-dom";
import homepage from "../../storage/homepage.json";
import visaSecure from "../../assets/images/Logotipi/Visa Secure/visa-secure_blu_72dpi.png";
import masterId from "../../assets/images/Logoi/Master Secure beli.svg";
import maestro from "../../assets/images/Logotipi/Maestro Card/Maestro - Black and Dark Backgrounds/ms_vrt_rev.svg";
import master from "../../assets/images/Logoi/Mastercard white.svg";
import dina from "../../assets/images/Logoi/dinacard-new.svg";
import visa from "../../assets/images/Logotipi/Visa/Visa_Brandmark_White_RGB_2021.png";

const Footer = ({ language }) => {
  const isMobile = useMediaQuery(`(max-width: 56.25em)`);
  const [data, setData] = useState(homepage);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
        alignItems: isMobile ? "flex-start" : "flex-start",
        padding: isMobile ? "1rem 1rem 1rem" : "4rem 6rem",
        backgroundColor: "#440086",
        width: "100%",
      }}
    >
      {" "}
      <Box
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "column",
          alignItems: isMobile ? "flex-start" : "flex-start",
          justifyContent: isMobile ? "center" : "flex-start",
        }}
      >
        <Text style={{ color: "#fff", fontWeight: 600 }}>
          {data[language].footer.customerCenter}
        </Text>
        <Link
          to="/terms-and-conditions"
          style={{ color: "#fff", textDecoration: "none" }}
        >
          {data[language].footer.terms}
        </Link>
        <Link to="/privacy" style={{ color: "#fff", textDecoration: "none" }}>
          {data[language].footer.privacy}
        </Link>
        <Link
          to="/cancelation"
          style={{ color: "#fff", textDecoration: "none" }}
        >
          {data[language].footer.cancelation}
        </Link>{" "}
        <Link
          to="/currency-exchange-statement"
          style={{ color: "#fff", textDecoration: "none" }}
        >
          {data[language].footer.currencyExchangeStatement}
        </Link>
        <Link to="/faq" style={{ color: "#fff", textDecoration: "none" }}>
          {data[language].footer.faq}
        </Link>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "column",
          alignItems: isMobile ? "flex-start" : "flex-start",
          justifyContent: isMobile ? "center" : "flex-start",
        }}
      >
        <Text style={{ color: "#fff", fontWeight: 600 }}>
          {data[language].footer.aboutUs}
        </Text>
        <Link
  to="#"
  onClick={() => {
    const element = document.getElementById("quick");
    const offset = -50; // Podesite ovu vrednost da biste podigli sekciju više (npr. -20 px)
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition + offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }}
  style={{ color: "#fff", textDecoration: "none" }}
>
  {data[language].footer.aboutRori}
</Link>


        <a
          href="mailto:support@rori.app"
          style={{ color: "#fff", textDecoration: "none" }}
        >
          {data[language].footer.contact}
        </a>
      </Box>{" "}
      <Box>
        {" "}
        <Text style={{ color: "#fff", fontWeight: 600 }}>
          {data[language].footer.socialNetworks}
        </Text>
        <Box
          style={{
            display: "flex",
            gap: "0.5rem",
            alignItems: isMobile ? "center" : "flex-start",
            justifyContent: isMobile ? "center" : "flex-start",
            marginRight: isMobile ? "1rem" : "12rem",
            marginTop: isMobile ? "1rem" : "0.5rem",
          }}
        >
          <a
            href="https://www.instagram.com/rori_app"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={ig}
              alt="ig"
              style={{
                width: "38px",
                height: "38px",
              }}
            />
          </a>
          <a
            href="https://www.facebook.com/roriapp "
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={fb}
              alt="fb"
              style={{
                width: "38px",
                height: "38px",
              }}
            />
          </a>
          <a
            href="https://x.com/Rori_app"
            target="_blank"
            rel="noreferrer"
            style={{
              height: "45.8px",
            }}
          >
            <Box
              style={{
                width: "38px",
                height: "38px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "2.5px solid #fff",
                borderRadius: "20%",
              }}
            >
              <img
                src={x}
                alt="in"
                style={{
                  width: "16px",
                  height: "16px",
                }}
              />
            </Box>
          </a>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: "auto",
            gap: "3px",
            width: "100%",
          }}
        >
          <img
            src={visa}
            alt="visa"
            style={{
              width: "65px",
              height: "50px",
              objectFit: "contain",
            }}
          />{" "}
          <img
            src={master}
            alt="master"
            style={{
              width: "65px",
              height: "50px",
              objectFit: "contain",
              padding: "5px",
            }}
          />
          <img
            src={maestro}
            alt="maestro"
            style={{
              width: "65px",
              height: "50px",
              objectFit: "contain",
            }}
          />
          <img
            src={dina}
            alt="dina"
            style={{
              width: "65px",
              height: "50px",
              objectFit: "contain",
            }}
          />
          <a
            href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html "
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={visaSecure}
              alt="visa secure"
              style={{
                width: "75px",
                height: "50px",
                objectFit: "contain",
              }}
            />
          </a>
          <a
            href="http://www.mastercard.com/rs/consumer/credit-cards.html"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={masterId}
              alt="master id"
              style={{
                width: "75px",
                height: "50px",
                objectFit: "contain",
              }}
            />
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
