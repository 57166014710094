import { Container, Group, Box, Image, Select, TextInput } from "@mantine/core";
import roriLogo from "../../assets/photos/logoRori.png";
import classes from "./Header.module.css";
import { useFaqData } from "../../stores/faq-data";
import { Link, useLocation } from "react-router-dom";

type HeaderProps = {
  language: string;
  setLanguage: Function;
};

export function Header({ language, setLanguage }: HeaderProps) {
  const { setSearchValue } = useFaqData();
  const location = useLocation();

  return (
    <header
      className={classes.header}
      style={{ display: location.pathname === "/" ? "none" : "block" }}
    >
      <Container className={classes.inner}>
        <Link to="/">
          <Image src={roriLogo} className={classes.logo} pt={10} />
        </Link>
        <Box>
          <Group justify="flex-end">
            <TextInput
              placeholder="Search"
              mt={10}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            <Select
              maw={80}
              mt={10}
              data={
                ["SR", "RU", "EN"]?.map((l: any) => {
                  return { value: l, label: l, key: l };
                }) || []
              }
              value={language}
              onChange={(v) => {
                setLanguage(v ? v : language);
              }}
            />
          </Group>
        </Box>
      </Container>
    </header>
  );
}
