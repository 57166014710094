import React, { useState } from "react";
import { Box, Image, Text, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import rori from "../../assets/images/rori.svg";
import R from "../../assets/images/R.png";
import gp from "../../assets/images/googleplay.webp";
import as from "../../assets/images/appstore.svg";
import homepage from "../../storage/homepage.json";

const Bottom = ({ language }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: 56.25em)`);
  const [data, setData] = useState(homepage);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
        padding: isMobile ? "2rem 1rem" : "4rem 6rem",
        backgroundColor: "#440086",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          alignItems: isMobile ? "center" : "flex-start",
        }}
      >
        <Box
          style={{
            display: "flex",
            whiteSpace: "nowrap",
            gap: "0.5rem",
          }}
        >
          <Text
            style={{
              color: "#ce0085",
              fontSize: isMobile ? "26px" : "36px",
              fontWeight: 700,
            }}
          >
            {data[language].bottom.part1}
          </Text>
          <Image
            src={rori}
            alt="rori"
            style={{
              height: isMobile ? "26px" : "36px",
              paddingTop: "2px",
            }}
          />
          <Text
            style={{
              color: "#ce0085",
              fontSize: isMobile ? "26px" : "36px",
              fontWeight: 700,
            }}
          >
            {data[language].bottom.part2}
          </Text>
        </Box>
        <Text
          style={{
            color: "#fff",
            lineHeight: isMobile ? "1.5rem" : "3rem",
            fontSize: isMobile ? "1rem" : "28px",
            textAlign: isMobile ? "center" : "left",
            fontWeight: 400,
          }}
        >
          {data[language].banner.short.split("\n")?.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </Text>
        {/* <Text
          style={{
            display: isMobile ? "block" : "none",
            backgroundColor: "#ce0085",
            textAlign: "center",
            color: "#fff",
            fontSize: "1rem",
            padding: "1rem",
            width: "94%",
            fontWeight: 700,
            borderRadius: "4rem",
          }}
        >
          DOWNLOAD APP NOW
        </Text> */}
        <Box
          style={{
            display: "flex",
            justifyContent: isMobile ? "center" : "left",
            alignItems: "center",
            gap: "1rem",
            maxWidth: "100vw",
            // overflow: "visible",
          }}
        >
          <a
            href="https://play.google.com/store/apps/details?id=com.rori.app.prod&pli=1"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
            }}
          >
            <Image
              src={gp}
              alt="gp"
              style={{
                width: isMobile ? "140px" : "260px",
                height: isMobile ? "40px" : "60px",
                objectFit: "cover",
              }}
            />
          </a>
          <a
            href="https://apps.apple.com/rs/app/rori/id6503948889"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              // overflow: "visible",
            }}
          >
            <Image
              src={as}
              alt="as"
              style={{
                width: isMobile ? "145px" : "260px",
                height: isMobile ? "39px" : "60px",
                objectFit: "cover",
              }}
            />
          </a>
        </Box>
      </Box>
      <Image
        src={R}
        alt="R"
        style={{
          width: isMobile ? "100%" : "25%",
          marginRight: isMobile ? "0" : "10rem",
        }}
      />
    </Box>
  );
};

export default Bottom;
