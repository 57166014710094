// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FaqHolder_wrapper__q8zPF {
    padding-top: calc(var(--mantine-spacing-lg) * 2);
    padding-bottom: calc(var(--mantine-spacing-xl) * 2);
    min-height: rem(650px);
  }
  
  .FaqHolder_title__mta9d {
    margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
  }
  
  .FaqHolder_item__p1Sms {
    border-radius: var(--mantine-radius-md);
    margin-bottom: var(--mantine-spacing-lg);
    border: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }`, "",{"version":3,"sources":["webpack://./src/pages/faq/FaqHolder.module.css"],"names":[],"mappings":"AAAA;IACI,gDAAgD;IAChD,mDAAmD;IACnD,sBAAsB;EACxB;;EAEA;IACE,oDAAoD;EACtD;;EAEA;IACE,uCAAuC;IACvC,wCAAwC;IACxC,2FAA2F;EAC7F","sourcesContent":[".wrapper {\n    padding-top: calc(var(--mantine-spacing-lg) * 2);\n    padding-bottom: calc(var(--mantine-spacing-xl) * 2);\n    min-height: rem(650px);\n  }\n  \n  .title {\n    margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);\n  }\n  \n  .item {\n    border-radius: var(--mantine-radius-md);\n    margin-bottom: var(--mantine-spacing-lg);\n    border: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FaqHolder_wrapper__q8zPF`,
	"title": `FaqHolder_title__mta9d`,
	"item": `FaqHolder_item__p1Sms`
};
export default ___CSS_LOADER_EXPORT___;
