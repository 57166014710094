import React, { useEffect, useState } from "react";
import faq from "../../storage/faqStorage.json"
import { Accordion, Box, Container, Title } from "@mantine/core";
import classes from './FaqHolder.module.css';
import { useFaqData } from "../../stores/faq-data";

type FaqHolderProps = {
    language: string;
}

const FaqHolder = ({ language }: FaqHolderProps) => {
    const [data, setData] = useState<any>(faq);
    const { searchValue } = useFaqData()


    useEffect(() => {
        if (searchValue) {
            data[language].categories?.map((c: any) => {
                c.questions?.map((q: any) => {
                    if (q.questionName.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 || q.response.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0) {
                        q.hide = false
                    } else {
                        q.hide = true
                    }
                })
            })
            setData(data)
        } else {
            let newObj = {...data}
            newObj[language].categories?.map((c: any) => {
                return c.questions?.map((q: any) => {
                    return q.hide = false
                })
            })
            setData(newObj);
        }
    }, [searchValue])

    return (
        <div>
            <Container size="sm" className={classes.wrapper}>
                <Title ta="center" style={{ color: "#440091" }} className={classes.title} mb={20}>
                    {language === 'SR' && "Česta pitanja"}
                    {language === 'EN' && "Frequently Asked Questions"}
                    {language === 'RU' && "Часто задаваемые вопросы"}
                </Title>
                {language && data ?
                    data[language].categories?.map((d: any) => {
                        return <Box key={d.name}><Title style={{ color: "#440091" }} order={4} mb={10} ta="left">{d.name}</Title>
                            <Accordion variant="separated">
                                {d.questions?.map((q: any) => {
                                    return (
                                        !q.hide &&
                                        <Accordion.Item key={q.questionName} className={classes.item} value={q.questionName}>
                                            <Accordion.Control>{q.questionName}</Accordion.Control>
                                            <Accordion.Panel>{q.response}</Accordion.Panel>
                                        </Accordion.Item>
                                    )
                                })}
                            </Accordion>
                        </Box>
                    })
                    : null
                }
            </Container>
        </div>
    )
}

export default FaqHolder