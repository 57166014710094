import React from "react";
import Banner from "../../components/home/Banner";
import How from "../../components/home/How";
import Download from "../../components/home/Download";
import Where from "../../components/home/Where";
import Emergency from "../../components/home/Emergency";
import Faq from "../../components/home/Faq";
import Bottom from "../../components/home/Bottom";
import Footer from "../../components/home/Footer";
import Career from "../../components/home/Career";
import { ParallaxProvider } from 'react-scroll-parallax';

type HomeProps = {
  language: string;
  setLanguage: (language: string) => void;
};
const Home = ({setLanguage, language }: HomeProps) => {
  return (
    <ParallaxProvider>
      <Banner language={language} setLanguage={setLanguage}/>
      <How language={language} />
      <Download language={language} />
      <Where language={language} />
      {/* <Emergency /> */}
      <Faq language={language} />
      <Career language={language} />
      <Bottom language={language} />
      <Footer language={language} />
    </ParallaxProvider>
  );
};

export default Home;
