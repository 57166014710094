import React, { useRef, useState } from "react";
import { Box, Button, Text, Input, FileInput, Textarea } from "@mantine/core";
import emailjs from "@emailjs/browser";
import { useMediaQuery } from "@mantine/hooks";
import { notifications } from '@mantine/notifications';
import homepage from "../../storage/homepage.json";

const CareerModal = ({ setOpen, position, language }) => {
  const isMobile = useMediaQuery(`(max-width: 56.25em)`);
  const form = useRef();
  const [data, setData] = useState(homepage);
  const [pending, setPending] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setPending(true);
    emailjs
      .sendForm(
        "service_i842uqm", // Zamenite sa vašim Service ID
        "template_78cfewy", // Zamenite sa vašim Template ID
        form.current,
        "FUyQEkYMn1xLs4Ocz" // Zamenite sa vašim User ID
      )
      .then(
        (result) => {
          console.log(result.text);
          notifications.show({
            title: data[language].career.successTitle,
            message: data[language].career.successMessage,
            color: 'green',
          });
            setPending(false);
          setOpen(false); // Zatvaranje modala nakon uspešnog slanja
        },
        (error) => {
          console.log(error.text);
          notifications.show({
            title: data[language].career.errorTitle,
            message: data[language].career.errorMessage,
            color: 'red',
          });
            setPending(false);
        }
      );
  };

  return (
    <Box
      style={{
        backgroundColor: "#440091",
        padding: "2rem",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",

        gap: "1rem",
        position: "relative",
        maxWidth: "80vw",
        margin: "0 auto",
      }}
    >
      <Button
        onClick={() => setOpen(false)}
        style={{
          position: "absolute",
          top: "5px",
          right: "0px",
          backgroundColor: "transparent",
          color: "#fff",
          fontSize: "1.5rem",
          border: "none",
          cursor: "pointer",
        }}
      >
        &times;
      </Button>
      <form
        ref={form}
        onSubmit={sendEmail}
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        <Text style={{ color: "#fff", fontSize: "20px", fontWeight: 700 }}>
          {data[language].career.joinRoriTeam}
        </Text>

        <Box
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: "1rem",
          }}
        >
          <Input
            placeholder={data[language].career.firstName}
            name="user_name"
            required
            radius="xl"
          />
          <Input
            placeholder={data[language].career.lastName}
            name="user_surname"
            required
            radius="xl"
          />
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: "1rem",
          }}
        >
          <Input
            placeholder={data[language].career.email}
            type="email"
            name="user_email"
            required
            radius="xl"
          />
          <Input
            placeholder={data[language].career.phone}
            name="user_phone"
            required
            radius="xl"
          />
        </Box>
        <Textarea
          placeholder={data[language].career.message}
          rows={3}
          name="user_message"
          required
          radius="md"
        />
        <input type="hidden" name="position" value={position} />
        <Button
          type="submit"
          style={{
            backgroundColor: "#D6168F",
            borderRadius: "30px",
            padding: "0.5rem 2rem",
            border: "none",
            outline: "none",
          }}
          disabled={pending}
        >
            {data[language].career.submit}
        </Button>
      </form>
    </Box>
  );
};

export default CareerModal;
