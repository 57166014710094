import { Box, useMantineTheme, Text, Button } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useState } from "react";
import homepage from "../../storage/homepage.json";
import driver from "../../assets/images/driver.png";
import pharmacist from "../../assets/images/pharmacist.png";
import CareerModal from "./CareerModal";


const Career = ({ language }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: 56.25em)`);
  const [data, setData] = useState(homepage);
 const [open, setOpen] = useState(false);
 const [position, setPosition] = useState("");
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: isMobile ? "2rem 1rem" : "1rem 4rem 6rem",
        backgroundColor: "#440086",
      }}
    >
      <Text
        style={{
          color: "#ce0085",
          fontSize: isMobile ? "26px" : "36px",
          padding: "2rem",

          fontWeight: 700,
          width: "100%",
        }}
      >
        {data[language].career.title}
      </Text>
      <Box
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "5rem",
          alignItems: isMobile ? "flex-end" : "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            // gap: "2rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={driver} alt="driver" style={{ height: "300px" }} />
          <Text
            style={{
              color: "#ce0085",
              fontSize: isMobile ? "26px" : "36px",
              fontWeight: 700,
            }}
          >
            {data[language].career.becomeDriver}
          </Text>
          <Text
            style={{
              color: "#fff",
              fontSize: isMobile ? "14px" : "20px",
              fontWeight: 400,
              textAlign: "center",
            }}
          >
            {data[language].career.driverDesc.split("\n").map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              );
            })}
          </Text>
          <Button
            style={{
              marginTop: "1rem",
              backgroundColor: "#D6168F",
              borderRadius: "30px",
              boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
              padding: "0.5rem 2rem",
              border: "none",
              outline: "none",
            }}
            onClick={()=>{setOpen(true); setPosition("kurir")}}
          >
            {data[language].career.joinRoriTeam}
          </Button>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            // gap: "2rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={pharmacist} alt="pharmacist" style={{ height: "250px" }} />
          <Text
            style={{
              color: "#ce0085",
              fontSize: isMobile ? "26px" : "36px",
              fontWeight: 700,
            }}
          >
            {data[language].career.joinPharmacy}
          </Text>
          <Text
            style={{
              color: "#fff",
              fontSize: isMobile ? "14px" : "20px",
              fontWeight: 400,
              textAlign: "center",
            }}
          >
            {data[language].career.pharmacistDesc
              .split("\n")
              .map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                );
              })}
          </Text>
          <Button
            color="black"
            style={{
              marginTop: "1rem",
              backgroundColor: "#D6168F",
              borderRadius: "30px",
              boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
              padding: "0.5rem 2rem",
              border: "none",
              outline: "none",
            }}
            onClick={()=>{setOpen(true); setPosition("farmaceut")}}
          >
            {data[language].career.joinRoriTeam}
          </Button>
        </Box>
      </Box>
      <Box
        style={{
          display: open ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 100,
        }}
      >
        <CareerModal setOpen={setOpen} position={position} language={language}/>
       
      </Box>
    </Box>
  );
};

export default Career;
