import React, { useEffect, useState } from "react";
import classes from '../faq/FaqHolder.module.css';
import { Box, Container, Text, Title } from "@mantine/core";
import termsData from "../../storage/terms.json"
import { useFaqData } from "../../stores/faq-data";


type PrivacyProps = {
    language: string;
}

const Terms = ({ language }: PrivacyProps) => {

    const [data, setData] = useState<any>(termsData);
    const { searchValue } = useFaqData()

    useEffect(() => {
        if (searchValue) {
            data[language].items?.map((i: any) => {
                if (i.title.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 || i.content.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0) {
                    i.hide = false
                } else {
                    i.hide = true
                }
            })
            setData(data)
        } else {
            let newObj = { ...data }
            newObj[language].items?.map((i: any) => {
                return i.hide = false
            })
            setData(newObj);
        }
    }, [searchValue])

    return (
        <Container size="sm" className={classes.wrapper}>
            <Title ta="center" style={{ color: "#440091" }} className={classes.title} mb={20}>
                {data[language].title}
            </Title>
            <Box p={30} mt={30}>
                <Text>{data[language].general}</Text>
                {
                    language && data ?
                        data[language].items?.map((d: any) => {
                            return !d.hide && <Box key={d.name}><Title style={{ color: "#440091" }} m={30} order={4} mb={10} ta="left">{d.title}</Title>
                                <Text>{d.content}</Text>
                            </Box>
                        })
                        : null
                }
            </Box>
        </Container>
    )
}

export default Terms