import React, { useState } from "react";
import { Box, Image, Text, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import banner from "../../assets/images/banner.svg";
import gp from "../../assets/images/googleplay.webp";
import as from "../../assets/images/appstore.svg";
import qr from "../../assets/images/qr.svg";
import homepage from "../../storage/homepage.json";

const Download = ({ language }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: 56.25em)`);
  const [data, setData] = useState(homepage);

  return (
    <Box
      style={{
        display: "flex",
        backgroundColor: "#ce0085",
        padding: isMobile ? "1rem" : "3rem",
      }}
    >
      <Box
        style={{
          width: isMobile ? "100%" : "40%",
          display: isMobile ? "none" : "block",
          position: "relative",
        }}
      >
        <Image
          src={banner}
          alt="banner"
          style={{
            position: "absolute",
            width: "40%",
            top: 0,
            right: 0,
            transform: "translate(-20%, 10%) rotate(-8deg)",
          }}
        />
        <Image
          src={banner}
          alt="banner"
          style={{
            position: "absolute",
            width: "40%",
            top: 0,
            right: 0,
            transform: "translate(-12%, 100%) rotate(10deg)",
            zIndex: 1,
          }}
        />
        <Image
          src={banner}
          alt="banner"
          style={{
            position: "absolute",
            width: "40%",
            top: 0,
            right: 0,
            transform: "translate(-22%, 140%) rotate(-8deg)",
          }}
        />
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: isMobile ? "center" : "flex-start",
          alignItems: isMobile ? "center" : "flex-start",
        }}
      >
        <Text
          style={{
            color: "#440086",
            fontSize: isMobile ? "1.5rem" : "3rem",
            fontWeight: 700,
            textAlign: "center",
            marginTop: "1rem",
          }}
        >
          {data[language].download.title}
        </Text>
        <Text
          style={{
            color: "#fff",
            fontSize: isMobile ? "1rem" : "1.5rem",
            fontWeight: 700,
          }}
        >
          {data[language].download.short}
        </Text>
        <Text
          style={{
            color: "#fff",
            backgroundColor: "#440086",
            padding: "0.5rem 2rem",
            borderRadius: "1.5rem",
            display: isMobile ? "block" : "none",
            margin: "1rem 0",
          }}
        >
          DOWNLOAD APP NOW
        </Text>
        <Box
          style={{
            display: "flex",
            justifyContent: isMobile ? "center" : "left",
            alignItems: "center",
            gap: "1rem",
            maxWidth: "100vw",
            marginTop: "1rem",
          }}
        >
          <a
            href="https://play.google.com/store/apps/details?id=com.rori.app.prod&pli=1"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
            }}
          >
            <Image
              src={gp}
              alt="gp"
              style={{
                width: isMobile ? "140px" : "260px",
                height: isMobile ? "40px" : "60px",
                objectFit: "cover",
              }}
            />
          </a>
          <a
            href="https://apps.apple.com/rs/app/rori/id6503948889"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
            }}
          >
            <Image
              src={as}
              alt="as"
              style={{
                width: isMobile ? "140px" : "260px",
                height: isMobile ? "40px" : "60px",
                objectFit: "cover",
              }}
            />
          </a>
          <Image
            src={qr}
            alt="qr"
            style={{
              display: isMobile ? "none" : "block",
              width: "100px",
              height: "60px",
              objectFit: "cover",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Download;
