import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Faq from "./pages/faq/FAQ";
import { AppShell, Box, MantineProvider } from "@mantine/core";
import { Header } from "./pages/faq/Header";
import "@mantine/core/styles.css";
import Privacy from "./pages/privacy/Privacy";
import Terms from "./pages/terms/Terms";
import Home from "./pages/home/Home";
import { useLocalStorage } from "@mantine/hooks";
import Quitting from "./pages/quitting/Quitting";
import ExchangeStatment from "./pages/exchangeStatment/ExchangeStatment";
import { Helmet } from "react-helmet";
import { Notifications } from "@mantine/notifications";
import '@mantine/notifications/styles.css';
import OpenInApp from "./components/global/OpenInApp";

function App() {
  const [language, setLanguage] = useLocalStorage<string>({
    key: "language",
    defaultValue: "SR",
  });

  const desc: { [key: string]: string } = {
    SR: "Rori - instant isporuka iz apoteke i drogerije",
    RU: "Rori - мгновенная доставка из аптек и дрогери",
    EN: "Rori - instant delivery from pharmacy and drogerie",
  };

  return (
    <MantineProvider
      theme={{
        fontFamily:
          'Proxima Nova, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      }}
    >
      <Helmet>
        <meta name="description" content={desc[language]} />
      </Helmet>

      <Router>
        <AppShell
          header={{ height: 60 }}
          navbar={{
            width: 0,
            breakpoint: "sm",
          }}
        >
          {" "}
          <Box
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              zIndex: 1000,
              padding: 10,
              width: "100%",
              maxWidth: 400,
            }}
          >
            <Notifications />
          </Box>
          {/* <OpenInApp /> */}
          <Header setLanguage={setLanguage} language={language} />
          <Routes>
            <Route
              path="/"
              element={<Home setLanguage={setLanguage} language={language} />}
            />
            <Route path="/faq" element={<Faq language={language} />} />
            <Route path="/privacy" element={<Privacy language={language} />} />
            <Route
              path="/cancelation"
              element={<Quitting language={language} />}
            />
            <Route
              path="/currency-exchange-statement"
              element={<ExchangeStatment language={language} />}
            />
            <Route
              path="/terms-and-conditions"
              element={<Terms language={language} />}
            />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
          </Routes>
        </AppShell>
      </Router>
    </MantineProvider>
  );
}

export default App;
