import React from "react";
import FaqHolder from "./FaqHolder";



type HomeProps = {
    language: string;
}

const Faq = ({ language }: HomeProps) => {
    return (
        <FaqHolder language={language} />
    )
}

export default Faq