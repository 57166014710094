import React, { useState } from "react";
import {
  Box,
  Image,
  Select,
  Text,
  useMantineTheme,
  Group,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import banner from "../../assets/images/banner.svg";
import gp from "../../assets/images/googleplay.webp";
import as from "../../assets/images/appstore.svg";
import landing from "../../assets/images/landing.png";
import R from "../../assets/images/R.png";
import rori from "../../assets/images/rori.svg";
import homepage from "../../storage/homepage.json";
import globeIcon from "../../assets/images/globe.png";
import srFlag from "../../assets/images/rs.svg";
import ruFlag from "../../assets/images/ru.svg";
import enFlag from "../../assets/images/gb.svg";
import { Parallax } from "react-scroll-parallax";

const languageData = [
  { value: "SR", label: "SR", flag: srFlag },
  { value: "RU", label: "RU", flag: ruFlag },
  { value: "EN", label: "EN", flag: enFlag },
];

const renderSelectOption = ({ option }) => (
  <Group>
    <Image
      src={option.flag}
      alt={`${option.label} flag`}
      width={20}
      height={20}
    />
    <Text
      style={{
        color: "white",
      }}
    >
      {option.label}
    </Text>
  </Group>
);

const Banner = ({ language, setLanguage }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: 56.25em)`);

  const [homepageData, setHomepageData] = useState(homepage);

  return (
    <Box
      style={{
        backgroundColor: "#440086",
        padding: isMobile ? "2rem 1rem 2rem" : "2rem 6rem 4rem",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Select
          data={languageData}
          value={language}
          onChange={(v) => setLanguage(v ? v : language)}
          renderOption={renderSelectOption}
          styles={{
            item: {
              display: "flex",
              alignItems: "center",
              color: "white",
              backgroundColor: "transparent",
            },
            input: {
              marginLeft: "auto",
              color: "white",
              backgroundColor: "transparent",
            },
            dropdown: { backgroundColor: "#440086" },
            placeholder: { color: "white" },
          }}
          leftSection={
            <img
              src={globeIcon}
              alt="Globe icon"
              style={{ width: 20, height: 20, filter: "invert(1)" }}
            />
          }
          leftSectionWidth={30}
          maxDropdownHeight={200}
          mt={10}
          mb={10}
          maw={100}
        />
      </div>
      <Box
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: isMobile ? "center" : "flex-start",
            gap: "1rem",
            marginTop: isMobile ? "1rem" : "8rem",
          }}
        >
          {/* <Parallax translateY={[-15, 15]}> */}
          <Image
            src={banner}
            alt="banner"
            style={{
              width: isMobile ? "232px" : "16vw",
            }}
          />
          {/* </Parallax> */}

          <Text
            style={{
              color: "#ce0085",
              fontSize: isMobile ? "2rem" : "4rem",
              textAlign: isMobile ? "center" : "left",
              fontWeight: 700,
            }}
          >
            {homepageData[language].banner.title}
          </Text>
          <Text
            style={{
              color: "#fff",
              lineHeight: isMobile ? "1.5rem" : "3rem",
              fontSize: isMobile ? "1rem" : "28px",
              textAlign: isMobile ? "center" : "left",
              fontWeight: 400,
            }}
          >
            {homepageData[language].banner.short
              .split("\n")
              ?.map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
          </Text>
          <Parallax translateY={[-15, 15]}>
            <Image
              src={landing}
              alt="landing"
              style={{
                display: isMobile ? "block" : "none",
                width: "100%",
              }}
            />
          </Parallax>
          <Box
            style={{
              display: "flex",
              justifyContent: isMobile ? "center" : "left",
              alignItems: "center",
              gap: "1rem",
              padding: "1rem",
              maxWidth: "100vw",
            }}
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.rori.app.prod&pli=1"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Image
                src={gp}
                alt="gp"
                style={{
                  width: isMobile ? "140px" : "260px",
                  height: isMobile ? "40px" : "60px",
                  objectFit: "cover",
                }}
              />
            </a>
            <a
              href="https://apps.apple.com/rs/app/rori/id6503948889"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Image
                src={as}
                alt="as"
                style={{
                  width: isMobile ? "145px" : "260px",
                  height: isMobile ? "40px" : "60px",
                  objectFit: "cover",
                }}
              />
            </a>
          </Box>
        </Box>
        <Box>
          <Parallax translateY={[-15, 15]}>
            <Image
              src={landing}
              alt="landing"
              style={{
                width: isMobile ? "100%" : "46vw",
                display: isMobile ? "none" : "block",
              }}
            />
          </Parallax>
        </Box>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "1rem",
          height: "100%",
        }}
        id="quick"
      >
        <Image
          src={R}
          alt="R"
          style={{
            width: "20%",
            display: isMobile ? "none" : "block",
          }}
        />
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginLeft: isMobile ? "0rem" : "1rem",
            gap: "1rem",
            paddingLeft: isMobile ? "0rem" : "6rem",
            borderLeft: isMobile ? "none" : "0.5px solid #fff",
          }}
        >
          <Box
            style={{
              display: "flex",
              whiteSpace: "nowrap",
              justifyContent: "center",
              alignItems: "left",
              gap: "0.5rem",
            }}
          >
            <Image
              src={rori}
              alt="rori"
              style={{
                height: isMobile ? "16px" : "36px",
                marginTop: "2px",
              }}
            />
            <Text
              style={{
                color: "#ce0085",
                fontSize: isMobile ? "16px" : "36px",
              }}
            >
              - {homepageData[language].banner.quick}
            </Text>
          </Box>
          <Text
            style={{
              color: "#fff",
              fontSize: isMobile ? "18px" : "28px",
              textAlign: "justify",
              fontWeight: 400,
              paddingRight: isMobile ? "1rem" : "6rem",
            }}
          >
            {homepageData[language].banner.desc}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
